<template>
  <div class="store">
    <div class="flex fwtj">
      <div
        class="item"
        v-for="(item, i) in list"
        :key="i"
       
      >
        <div class="img"  @click="openpage('/fwdetail', item.id)">
          <img :src="item.thumb_images" alt="暂无图片" v-if="item.thumb_images" />
        </div>
        <div class="btn2">
          <div class="name"  @click="openpage('/fwdetail', item.id)">{{ item.title }}</div>
          <div class="intro">{{ item.description }}</div>
          <div class="flex zixun">
            <div class="btn btn-warning-sm" @click="cancelstar(item.id)">取消收藏</div>
            <div class="btn btn-primary-sm"  @click="openpage('/fbxq',item.id)">发布需求</div>
          </div>
        </div>
      </div>
    </div>
     <div class="pagination" v-show="total>0">
        <el-pagination
    background
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="9"
        layout="total, prev, pager, next"
        :total="total">
    </el-pagination>
      </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    // 这里存放数据
    return {
       currentPage:1,
       total:0,
       list: [],
          
    };
  },
 mounted(){
      this.handleCurrentChange(this.currentPage)
  },
   methods: {
      handleCurrentChange(val) {
        this.$http.favbyproduct({
          offset:(val-1)*9,
          limit:9, 
        }).then(res=>{
          this.list=res.data.list;
          this.total=res.data.total;
        })
      },
     openpage(path,id){
        this.$router.push({
            path,
            query: {
              id,
            },
        })
     },
     cancelstar(id){
        this.$http.productfav({id}).then((res) => {
         this.handleCurrentChange(this.currentPage)
           this.$message.success({ message: "取消收藏成功!" });
          
       });
     }
  },
};
</script>
<style scoped lang="scss">
@import "~@/styles/mixin.scss";
.store {
  padding: 40px 20px;
}
.pagination{
  // background: #fff;
  text-align: center;
  padding: 20px ;
}
.fwtj {
  width: 100%;
  flex-wrap: wrap;
  //  padding: 0 20px;
//   justify-content: space-evenly;
  .item {
    width: calc(33.3% - 14px);
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
     cursor: pointer;
     border: 1px solid #ccc;
    &:nth-child(3n+3){
        margin-right: 0;
    }
    .img img{
      width: 100%;
       height: 200px;
      object-fit: cover;
      display: block;
    }
  }

  .btn2 {
    padding:10px 20px ;
    font-size: 20px;
    margin-bottom: 10px;
    .name {
        font-size: 20px;
        margin-bottom: 6px;
         @include single-line-ellipsis;
    }
    .intro{
        font-size: 14px;
        color: #666;
        @include  multi-line-ellipsis(2)
    }
    .zixun {
        margin-top: 10px;
      justify-content: space-between;
    }
  }
}
</style>
